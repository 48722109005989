import React from "react";
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DraftsIcon from '@material-ui/icons/Drafts';

import "./Footer.css";
import { Link } from "react-router-dom";

export default function Footer(props) {
  return (
    <div className="website-footer">
      <div className="footer-overlay"></div>

      <div className="footer-content-section">
        <h2>About</h2><br />
        <div className="footer-about-content">
          Synapes Life Sciences Pvt. Ltd. is rated as the fastest growing
          pharmaceutical organization. The vision that it has believed in "to
          Save Lives Through Life Saving Drugs" has earned it tremendous
          goodwill of being a respected and reputed. In addition, professional
          ethics govern policies regarding Finance & Accounting, Human Resource,
          Sales and Marketing, Production .
        </div>
      </div>
      <div className="footer-content-section">
        <h2>Contact Details</h2><br />
        <div className = "footer-contact-element">
            <LocationOnIcon className = "footer-icon"/> 310 Aggarwal Chamber, Plot No.4, Sector 12, Dwarka, New Delhi-110078
        </div>
        <div className = "footer-contact-element">
            <AddIcCallIcon className = "footer-icon"/> 011-49124675
        </div>
        <div className = "footer-contact-element">
            <DraftsIcon className = "footer-icon"/> synapeslifesc@gmail.com
        </div>
      </div>
      <div className="footer-content-section">
        <h2>Useful Links</h2><br />
        <Link to = "/" className = "footer-links">Home</Link>
        <Link to = "/about" className = "footer-links">About</Link>
        <Link to = "/products" className = "footer-links">Products</Link>
        <Link to = "/contact" className = "footer-links">Contact us</Link>
      </div>
    </div>
  );
}
